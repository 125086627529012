import axios from "axios";
import { StationBaseUrl, baseApiVersion,StationBaseApiPreFix, headers } from "../../config/env";

/**
 * 스케쥴 생성
 * @param {*} params 
 * @returns 
 */
export function createStationSchedule(params) {
    return axios.post(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/schedule`,params, {
        headers,
    });
}

/**
 * 스케줄 업데이트
 * @param {*} scheduleId 
 * @param {*} params 
 * @returns 
 */
export function updateStationSchedule(scheduleId, params) {
    return axios.patch(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/schedule/${scheduleId}`,params, {
        headers,
    });
}

/**
 * 스케줄 타임라인 GET
 * @param {params} 
 * @returns 
 */
export function fetchStationScheduleTimeline(params) {
    return axios.get(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/schedule/localdate/timeline`,{
        params,
        headers,
    });
}

/**
 * 월간 스케줄의 일별 개수를 받아옴 
 * @param {string, string} 월의 시작일자, 월의 마지막일자
 * @returns 해당월의 일별로 배열에담아 count리턴 
 */
export function fetchStationScheduleCount(params) {
    return axios.get(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/schedule/localdate/count`,{
        params,
        headers,
    });
}

/**
 * 스케줄 상세정보
 * @param {*} sheduleId 
 * @returns 
 */
export function fetchStationScheduleDetail(sheduleId) {
    return axios.get(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/schedule/${sheduleId}`,{
        headers,
    });
}

/**
 * 스케줄 취소 
 * 만약 반복비행의 경우, 선택한 일자만 취소되고 나머지일자는 취소되지않는다. 반복일정을 전체삭제할경우 delete로 요청보내야함 
 * @param {*} sheduleId 
 * @param {*} params 
 * @returns 
 */
export function cancelStationSchedule(sheduleId,params) {
    return axios.patch(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/schedule/${sheduleId}/cancel`,params, {
        headers,
    });
}

/**
 * 스케줄 DELETE
 * @param {*} sheduleId 
 * @returns 
 */
export function deleteStationSchedule(sheduleId) {
    return axios.delete(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/schedule/${sheduleId}`, {
        headers,
    });
}





export function getScheduleTypes() {
    return [{
            value : null,
            name : "전체"
        },
        {
            value : 'Immediately',
            name : "즉시비행"
        },
        {
            value : 'Reserve',
            name : "예약비행"
        },
        {
            value : 'Period',
            name : "반복비행"
        }
    ]
}
