import axios from "axios";
import { baseUrl, baseApiVersion, headers } from "../config/env";

export function fetchWeatherEvent(eventId) {
  return axios.get(baseUrl + baseApiVersion + `/weather/event/${eventId}`, { headers });
}

export function humidityBinding(inputData) {
  if(inputData == undefined) {
    return "ic-minus";
  }

  let data = Number(inputData.split(" ")[0]);
  let humidity;
  if (0 <= data && data < 20) {
    humidity = 1;
  } else if (20 <= data && data < 40) {
    humidity = 2;
  } else if (40 <= data && data < 80) {
    humidity = 3;
  } else if (80 <= data && data < 90) {
    humidity = 4;
  } else if (90 <= data && data <= 100) {
    humidity = 5;
  }

  return "humidity_"+humidity;
}

export function imgBinding(data) {
  let imgName;
  if(data.thunderstroke == undefined|| data.rainCode == undefined || data.skyCode == undefined ) {
    return "ic-minus";
  }

  if (data.thunderstroke != "0") {
    imgName = "thunder";
  } else if (data.rainCode != "0") {
    imgName = "raincode_" + data.rainCode;
  } else if (data.skyCode != "0") {
    imgName = "skycode_" + data.skyCode;
  }
  return imgName;
}